// src/components/ExcelPreviewModal/CustomCalendar.js
import React, { useState, useEffect } from 'react';
import { Grid, Button, Header, Icon, Popup } from 'semantic-ui-react';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  addMonths,
  subMonths,
  isSameMonth,
  isSameDay,
  isWithinInterval,
  parseISO,
  isAfter,
} from 'date-fns';
import './CustomCalendar.css';
import useAxios from '../../axiosSetup'; // Import your custom useAxios hook
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomCalendar = ({
  viewMode,
  currentWeek,
  selectedDay,
  onWeekChange,
  onDayChange,
  region,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [holidays, setHolidays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const headerFormat = 'MMMM yyyy';
  const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  const axiosInstance = useAxios(); // Initialize axiosInstance

  // Fetch holidays when region changes
  useEffect(() => {
    const fetchHolidays = async () => {
      if (!region) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosInstance.get('/api/location-calendar', {
          params: { locations: region },
        });

        setHolidays(response.data);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch holidays.');
        console.error('Error fetching holidays:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHolidays();
  }, [region, axiosInstance]);

  useEffect(() => {
    if (viewMode === 'week' && currentWeek) {
      setCurrentDate(currentWeek);
    } else if (viewMode === 'day' && selectedDay) {
      setCurrentDate(selectedDay);
    }
  }, [viewMode, currentWeek, selectedDay]);

  const renderHeader = () => {
    return (
      <Grid.Row className="header-row" verticalAlign="middle">
        <Grid.Column width={2} textAlign="left">
          <Button
            icon
            onClick={() => setCurrentDate(subMonths(currentDate, 1))}
            title="Previous Month"
          >
            <Icon name="chevron left" />
          </Button>
        </Grid.Column>
        <Grid.Column width={12} textAlign="center">
          <Header as="h2">
            {format(currentDate, headerFormat)}
            {isLoading && <Icon loading name="spinner" size="small" />}
          </Header>
        </Grid.Column>
        <Grid.Column width={2} textAlign="right">
          <Button
            icon
            onClick={() => setCurrentDate(addMonths(currentDate, 1))}
            title="Next Month"
          >
            <Icon name="chevron right" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const renderDays = () => {
    return (
      <Grid.Row>
        {daysOfWeek.map((day, index) => (
          <Grid.Column key={index} textAlign="center" className="day-header">
            {day}
          </Grid.Column>
        ))}
      </Grid.Row>
    );
  };

  const getHolidaysByDate = (day) => {
    return holidays.filter((holiday) => isSameDay(day, parseISO(holiday.date)));
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to start of the day

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isToday = isSameDay(day, new Date());

        let isSelected = false;
        if (viewMode === 'week' && currentWeek) {
          const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
          const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });
          isSelected = isWithinInterval(day, { start: weekStart, end: weekEnd });
        } else if (viewMode === 'day' && selectedDay) {
          isSelected = isSameDay(day, selectedDay);
        }

        const dayHolidays = getHolidaysByDate(day);
        const hasHoliday = dayHolidays.length > 0;

        // Determine if the day is in the future
        const isFuture = isAfter(day, today);

        // Determine if the week is in the future
        let isWeekFuture = false;
        if (viewMode === 'week') {
          const weekStart = startOfWeek(day, { weekStartsOn: 1 });
          const currentWeekStart = startOfWeek(today, { weekStartsOn: 1 });
          isWeekFuture = isAfter(weekStart, currentWeekStart);
        }

        // **Wrap the Button in a span to allow Popup on disabled Buttons**
        const button = (
          <Button
            className="day-button"
            onClick={() => handleDateClick(cloneDay)}
            disabled={isFuture || (viewMode === 'week' && isWeekFuture)}
            title={
              isFuture
                ? 'Cannot select future dates'
                : viewMode === 'week' && isWeekFuture
                ? 'Cannot select future weeks'
                : undefined
            }
          >
            {formattedDate}
          </Button>
        );

        const wrappedButton = hasHoliday ? (
          <Popup
            content={
              <div>
                {dayHolidays.map((holiday, index) => (
                  <div key={index}>
                    <strong>{holiday.country}:</strong> {holiday.name}
                  </div>
                ))}
              </div>
            }
            trigger={
              <span className="popup-wrapper">
                {button}
              </span>
            }
            position="top center"
            inverted
            size="tiny"
            mouseEnterDelay={100}
            mouseLeaveDelay={100}
            hoverable
          />
        ) : (
          button
        );

        days.push(
          <Grid.Column
            key={day}
            className={`day-cell ${
              !isSameMonth(day, monthStart) ? 'disabled' : ''
            } ${isToday ? 'today' : ''} ${isSelected ? 'selected' : ''} ${
              hasHoliday ? 'holiday' : ''
            }`}
          >
            {wrappedButton}
          </Grid.Column>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Grid.Row key={day} className="calendar-cells-row">
          {days}
        </Grid.Row>
      );
      days = [];
    }
    return <>{rows}</>;
  };

  const handleDateClick = (day) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to start of the day

    if (viewMode === 'week') {
      const weekStart = startOfWeek(day, { weekStartsOn: 1 });
      const currentWeekStart = startOfWeek(today, { weekStartsOn: 1 });

      // Allow selecting the current week
      if (isSameWeek(weekStart, currentWeekStart)) {
        onWeekChange(weekStart);
        return;
      }

      // Prevent selecting future weeks
      if (isAfter(weekStart, currentWeekStart)) {
        toast.warn('Cannot select a future week.');
        return;
      }

      // If the week is in the past, allow selection
      onWeekChange(weekStart);
    } else if (viewMode === 'day') {
      // Ensure selected day is not in the future
      if (isAfter(day, today)) {
        toast.warn('Cannot select a future date.');
        return;
      }

      onDayChange(day);
    }
  };

  // Helper to check if two weeks are the same
  const isSameWeek = (weekStart1, weekStart2) => {
    return isSameDay(weekStart1, weekStart2);
  };

  if (error) {
    return (
      <div className="calendar-error">
        <Icon name="warning sign" />
        Error loading holidays: {error}
      </div>
    );
  }

  return (
    <div className="calendar-container">
      <Grid columns={7} divided className="calendar-grid">
        {renderHeader()}
        {renderDays()}
        {renderCells()}
      </Grid>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default CustomCalendar;
