// ClientDetails.jsx
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Table, Icon, Button, Modal, Form, Dropdown, Message, Loader, Popup } from 'semantic-ui-react';
import * as XLSX from 'xlsx'; // Import SheetJS
import { saveAs } from 'file-saver'; // Import FileSaver
import AllocationModalProjects from '../../components/AllocationModal/AllocationModalProjects'; // Import AllocationModal
import { UserContext } from '../../context/UserContext'; // Import the UserContext
import './ClientDetails.css'; // Import Pagination CSS
import { LuRefreshCw } from "react-icons/lu";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify CSS
import useAxios from '../../axiosSetup'; // Import the custom axios hook

// Helper function to generate page numbers with ellipsis
const generatePageNumbers = (totalPages, currentPage) => {
  const pages = [];

  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Always show first two pages
    pages.push(1, 2);

    if (currentPage > 4) {
      pages.push('ellipsis1');
    }

    const start = Math.max(3, currentPage - 1);
    const end = Math.min(totalPages - 2, currentPage + 1);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 3) {
      pages.push('ellipsis2');
    }

    // Always show last two pages
    pages.push(totalPages - 1, totalPages);
  }

  return pages;
};

const ClientDetails = () => {
  const { userRole } = useContext(UserContext);
  const navigate = useNavigate();
  const { clientId, projectId } = useParams();
  const location = useLocation();

  // Initialize axiosInstance
  const axiosInstance = useAxios();

  // State variables to store client and project names
  const [clientName, setClientName] = useState('');
  const [projectName, setProjectName] = useState('');

  const [open, setOpen] = useState(false);
  const [allocationData, setAllocationData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [filter, setFilter] = useState('active'); // Set default filter to 'active'
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isDownloading, setIsDownloading] = useState(false); // New state for download process

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [allocationToDelete, setAllocationToDelete] = useState(null);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const rowsPerPage = 20; // Rows per page set to 20

  // Helper function to sanitize sheet names
  const sanitizeSheetName = (name) => {
    // Remove invalid characters and limit to 31 characters
    return name.replace(/[/\\?*[\]:]/g, '_').substring(0, 31);
  };

  // Fetch allocations from the backend API based on selected filter
  const fetchAllocations = async (currentFilter) => {
    setLoading(true);
    setError(null);
    try {
      let endpoint = `/api/project-details/${clientId}/${projectId}`;
      if (currentFilter) {
        endpoint += `?filter=${encodeURIComponent(currentFilter)}`;
      }

      const response = await axiosInstance.get(endpoint);

      const data = response.data;
      console.log('Fetched Data:', data); // Add this line for debugging
      setClientName(data.clientName);
      setProjectName(data.projectName);
      setEmployeesData(data.allocations);
      setCurrentPage(1); // Reset to first page when data changes
    } catch (error) {
      console.error('Fetch error:', error);

      if (error.response) {
        if (error.response.status === 404) {
          // Handle 404 by assuming no allocations found
          setClientName('Unknown Client'); // Or handle appropriately
          setProjectName('Unknown Project'); // Or handle appropriately
          setEmployeesData([]); // Clear allocations
        } else {
          // Handle other server-side errors
          setError(error.response.data.message || `Error: ${error.response.status} ${error.response.statusText}`);
        }
      } else if (error.request) {
        // Handle network errors
        setError('No response from server. Please check your network.');
      } else {
        // Handle other errors
        setError('Error: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllocations(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, projectId, filter]);

  // Handle Filter Change
  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  // Handle Sort
  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  // Sort Data
  const sortedData = useMemo(() => {
    if (sortConfig.key === null) return employeesData;

    const sorted = [...employeesData].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle null or undefined
      if (aValue === null || aValue === undefined) aValue = '';
      if (bValue === null || bValue === undefined) bValue = '';

      // If sorting by AllocationPercent, convert to number
      if (sortConfig.key === 'AllocationPercent') {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      } else {
        // Convert to uppercase string for case-insensitive sorting
        aValue = aValue.toString().toUpperCase();
        bValue = bValue.toString().toUpperCase();
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  }, [employeesData, sortConfig]);

  // Enhanced Download Excel Function
  const downloadExcel = async () => {
    setIsDownloading(true);
    setError(null);

    try {
      const filters = ['active', 'closed', 'all'];
      const sheetNames = {
        active: 'Active',
        closed: 'Closed',
        all: 'All'
      };

      // Fetch allocations for each filter individually
      const allocationPromises = filters.map(filterKey =>
        axiosInstance.get(`/api/project-details/${clientId}/${projectId}?filter=${encodeURIComponent(filterKey)}`)
      );

      const responses = await Promise.all(allocationPromises);

      // Initialize workbook
      const workbook = XLSX.utils.book_new();
      let finalClientName = '';
      let finalProjectName = '';

      // Process each filter's data
      responses.forEach((response, index) => {
        const { clientName: cName, projectName: pName, allocations } = response.data;

        // Set client and project names if not already set
        if (!finalClientName) finalClientName = cName || 'Client';
        if (!finalProjectName) finalProjectName = pName || 'Project';

        // Map allocations to worksheet data
        const worksheetData = allocations.map(alloc => ({
          'Employee Name': alloc.EmployeeName || 'N/A',
          'Role': alloc.EmployeeRole || 'N/A',
          'Allocation %': alloc.AllocationPercent !== undefined ? `${alloc.AllocationPercent}%` : 'N/A',
          'Allocation Status': alloc.AllocationStatus || 'N/A',
          'Billing Type': alloc.AllocationBillingType || 'N/A',
          'Billed Check': alloc.AllocationBilledCheck || 'N/A',
          'TimeSheet Approver': alloc.AllocationTimeSheetApprover || 'N/A',
          'Start Date': alloc.AllocationStartDate ? new Date(alloc.AllocationStartDate).toLocaleDateString() : 'N/A',
          'End Date': alloc.AllocationEndDate ? new Date(alloc.AllocationEndDate).toLocaleDateString() : 'N/A',
          'Modified By': alloc.ModifiedBy || 'N/A',
          'Modified At': alloc.ModifiedAt ? new Date(alloc.ModifiedAt).toLocaleString() : 'N/A'
        }));

        // If no allocations, add headers with a note
        if (worksheetData.length === 0) {
          worksheetData.push({
            'Employee Name': 'No allocations found for this filter.',
            'Role': '',
            'Allocation %': '',
            'Allocation Status': '',
            'Billing Type': '',
            'Billed Check': '',
            'TimeSheet Approver': '',
            'Start Date': '',
            'End Date': '',
            'Modified By': '',
            'Modified At': ''
          });
        }

        // Convert JSON to worksheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);

        // Append worksheet to workbook with sanitized sheet name
        const sheetName = sanitizeSheetName(sheetNames[filters[index]] || filters[index]);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });

      // Generate Excel file as a buffer
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob from the buffer with the correct MIME type
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Define the filename with current date
      const currentDate = new Date().toISOString().split('T')[0];
      const filename = `${finalClientName}_${finalProjectName}_Allocations_${currentDate}.xlsx`;

      // Trigger the download
      saveAs(dataBlob, filename);

      // Notify the user that the download has started
      toast.success('Excel download initiated successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.error('Error during Excel download:', error);

      if (error.response) {
        // Server-side error
        setError(`Error: ${error.response.status} - ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        // Network error
        setError('No response from server. Please check your network.');
      } else {
        // Other errors
        setError('Error: ' + error.message);
      }
    } finally {
      setIsDownloading(false);
    }
  };

  // Handle Back Click
  const handleBackClick = () => {
    navigate(-1);
  };

  // Handle Edit Allocation
  const handleEditAllocation = (alloc) => {
    setAllocationData(alloc);
    setOpen(true);
  };

  // Handle Delete Allocation
  const handleDeleteAllocation = (allocationId) => {
    setAllocationToDelete(allocationId);
    setConfirmDelete(true);
  };

  // Confirm Delete
  const confirmDeleteAllocation = async () => {
    try {
      const response = await axiosInstance.delete(`/api/allocations/${allocationToDelete}`);
      // Assuming a successful delete returns 200 or 204
      toast.success('Allocation deleted successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      fetchAllocations(filter);
    } catch (error) {
      console.error('Delete error:', error);

      if (error.response) {
        setError(error.response.data.message || `Error: ${error.response.status} ${error.response.statusText}`);
      } else if (error.request) {
        setError('No response from server. Please check your network.');
      } else {
        setError('Error: ' + error.message);
      }
    } finally {
      setConfirmDelete(false);
      setAllocationToDelete(null);
    }
  };

  // Handle Add Allocation
  const handleAddAllocation = () => {
    setAllocationData(null);
    setOpen(true);
  };

  // Pagination Logic
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  // Handle page change
  const paginate = (pageNumber) => {
    if (pageNumber === 'ellipsis1' || pageNumber === 'ellipsis2') return;
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  // Generate pagination numbers with ellipsis
  const paginationPages = generatePageNumbers(totalPages, currentPage);

  // Get current employees
  const indexOfLastEmployee = currentPage * rowsPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - rowsPerPage;
  const currentEmployees = sortedData.slice(indexOfFirstEmployee, indexOfLastEmployee);

  // Handle location state for filter
  useEffect(() => {
    if (location.state?.filter) {
      setFilter(location.state.filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <div className="main-layout">
      <div className='right-content'>
        {/* Breadcrumb Navigation */}
        <div className='breadcrumb'>
          {/* Back Arrow Icon */}
          <Icon 
            name="arrow left" 
            size="large" 
            className="icon"
            onClick={handleBackClick} 
            style={{ cursor: 'pointer' }}
          />
          
          {/* Previous Screen Link */}
          <h2 
            className="breadcrumb-text" 
            onClick={() => navigate('/allocations/projects')}
            style={{ cursor: 'pointer', display: 'inline', marginLeft: '10px' }}
          >
            Clients
          </h2>
          
          {/* Divider between breadcrumb items */}
          <span className="breadcrumb-divider"> / </span>
          
          {/* Current Client Name */}
          <h2 
            className="breadcrumb-text" 
            onClick={() => navigate(`/client/${clientId}/projects`)}
            style={{ cursor: 'pointer', display: 'inline', marginLeft: '10px' }}
          >
            {clientName || 'Loading...'}
          </h2>
          
          {/* Divider between breadcrumb items */}
          <span className="breadcrumb-divider"> / </span>
          
          {/* Current Project Name */}
          <h2 className="breadcrumb-text" style={{ display: 'inline' }}>
            {projectName || 'Loading...'}
          </h2>
          <div className='breadcrumb-actions'>
            {/* Conditionally render the Save/Submit button based on allocation percentage */}
          </div>
        </div>

        {/* Control Buttons */}
        <div className='controls' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          {/* Filter Tabs */}
          <div className="filter-tabs" style={{ display: 'flex', gap: '10px', flexGrow: 1 }}>
            <button
              className={`tab ${filter === 'active' ? 'active' : ''}`}
              onClick={() => handleFilterChange('active')}
            >
              Active
            </button>
            <button
              className={`tab ${filter === 'closed' ? 'active' : ''}`}
              onClick={() => handleFilterChange('closed')}
            >
              Closed
            </button>
            <button
              className={`tab ${filter === 'all' ? 'active' : ''}`}
              onClick={() => handleFilterChange('all')}
            >
              All
            </button>
          </div>

          {/* Download and Allocate Buttons */}
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {userRole === 'bizops' && (
              <Button 
                positive 
                icon="plus" 
                onClick={handleAddAllocation} 
                content="Allocate Resource" 
              />
            )}
            <Button
              icon
              labelPosition="left"
              color="blue"
              onClick={downloadExcel}
              className="download-button"
              disabled={isDownloading} // Disable button during download
            >
              {isDownloading ? (
                <>
                  <Loader active inline size='small' /> Downloading...
                </>
              ) : (
                <>
                  <Icon name="download" />
                  Download
                </>
              )}
            </Button>
          </div>
        </div>

        {/* Display Success Message */}
        {showMessage && (
          <Message
            success
            header='Success'
            content='Action completed successfully.'
            onDismiss={() => setShowMessage(false)}
          />
        )}

        {/* Display Error Message */}
        {error && (
          <Message
            negative
            header='Error'
            content={error}
            onDismiss={() => setError(null)}
          />
        )}

        {/* Allocations Table */}
        <div className='table-container'>
          {loading ? (
            <Loader active inline='centered' size='large'>
              Loading allocations...
            </Loader>
          ) : (
            <>
              <Table celled sortable style={{ width: '100%' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'EmployeeName' ? sortConfig.direction : null}
                      onClick={() => handleSort('EmployeeName')}
                    >
                      Employee Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'EmployeeRole' ? sortConfig.direction : null}
                      onClick={() => handleSort('EmployeeRole')}
                    >
                      Role
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationPercent' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationPercent')}
                    >
                      Allocation %
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationStatus' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationStatus')}
                    >
                      Allocation Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationBillingType' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationBillingType')}
                    >
                      Billing Type
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationBilledCheck' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationBilledCheck')}
                    >
                      Billed Check
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationTimeSheetApprover' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationTimeSheetApprover')}
                    >
                      TimeSheet Approver
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationStartDate' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationStartDate')}
                    >
                      Start Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'AllocationEndDate' ? sortConfig.direction : null}
                      onClick={() => handleSort('AllocationEndDate')}
                    >
                      End Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'ModifiedBy' ? sortConfig.direction : null}
                      onClick={() => handleSort('ModifiedBy')}
                    >
                      Modified By
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortConfig.key === 'ModifiedAt' ? sortConfig.direction : null}
                      onClick={() => handleSort('ModifiedAt')}
                    >
                      Modified At
                    </Table.HeaderCell>
                    {userRole === 'bizops' && (
                      <Table.HeaderCell>
                        Actions
                      </Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {currentEmployees.length > 0 ? (
                    currentEmployees.map((employee, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Icon name="user" /> {employee.EmployeeName || 'N/A'}
                        </Table.Cell>
                        <Table.Cell>{employee.EmployeeRole || 'N/A'}</Table.Cell>
                        <Table.Cell>{employee.AllocationPercent}%</Table.Cell>
                        <Table.Cell>{employee.AllocationStatus}</Table.Cell>
                        <Table.Cell>{employee.AllocationBillingType}</Table.Cell>
                        <Table.Cell>{employee.AllocationBilledCheck}</Table.Cell>
                        <Table.Cell>{employee.AllocationTimeSheetApprover}</Table.Cell>
                        <Table.Cell>{employee.AllocationStartDate ? new Date(employee.AllocationStartDate).toLocaleDateString() : 'N/A'}</Table.Cell>
                        <Table.Cell>{employee.AllocationEndDate ? new Date(employee.AllocationEndDate).toLocaleDateString() : 'N/A'}</Table.Cell>
                        <Table.Cell>{employee.ModifiedBy}</Table.Cell>
                        <Table.Cell>{employee.ModifiedAt ? new Date(employee.ModifiedAt).toLocaleString() : 'N/A'}</Table.Cell>
                        {userRole === 'bizops' && (
                          <Table.Cell>
                            <Button 
                              icon="edit" 
                              onClick={() => handleEditAllocation(employee)} 
                              title="Edit Allocation"
                              size="small"
                            />
                            <Button 
                              icon="trash" 
                              color="red" 
                              onClick={() => handleDeleteAllocation(employee.AllocationID)} 
                              title="Delete Allocation"
                              size="small"
                            />
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={userRole === 'bizops' ? "13" : "12"} textAlign="center">
                        {error ? error : 'No allocations found.'}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>

              {/* Enhanced Pagination Section */}
              {totalPages > 1 && (
                <div className="pagination">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button"
                    aria-label="Previous Page"
                  >
                    Back
                  </button>

                  {paginationPages.map((page, index) => (
                    page === 'ellipsis1' || page === 'ellipsis2' ? (
                      <span key={index} className="pagination-ellipsis">...</span>
                    ) : (
                      <button
                        key={page}
                        onClick={() => paginate(page)}
                        className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                        aria-label={`Page ${page}`}
                      >
                        {page}
                      </button>
                    )
                  ))}

                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                    aria-label="Next Page"
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* Allocation Modal */}
        <AllocationModalProjects
          open={open}
          onClose={() => setOpen(false)}
          onSave={() => {
            toast.success('Allocation saved successfully!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            fetchAllocations(filter);
          }}
          allocationData={allocationData}
          clientProjectData={!allocationData ? { clientId, projectId } : undefined}
          userRole={userRole}
        />

        {/* Confirm Delete Modal */}
        <Modal
          open={confirmDelete}
          size="small"
        >
          <Modal.Header>Confirm Delete</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this allocation?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setConfirmDelete(false)}>
              No
            </Button>
            <Button positive onClick={confirmDeleteAllocation}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        
        {/* Toast Container for Notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default ClientDetails;
