// src/context/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; // Correct default import
import axios from 'axios';
import config from '../Config.json';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(null);
    const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken') || null);
    const [refreshToken, setRefreshToken] = useState(sessionStorage.getItem('refreshToken') || null); // New state for refresh token
    const [tokenExpiry, setTokenExpiry] = useState(null);

    // Function to decode JWT and get expiry
    const decodeToken = (token) => {
        try {
            const decoded = jwtDecode(token);
            return decoded.exp;
        } catch (error) {
            console.error('Invalid JWT token:', error);
            return null;
        }
    };

    // Function to refresh JWT token
    const refreshAccessToken = async () => {
        if (!refreshToken) {
            handleLogout();
            return;
        }

        try {
            const response = await axios.post(`${config.azureApiUrl}/api/refreshToken`, {}, {
                headers: {
                    Authorization: `Bearer ${refreshToken}`, // Use refresh token
                },
            });
            const newAccessToken = response.data.accessToken;
            setJwtToken(newAccessToken);
            sessionStorage.setItem('jwtToken', newAccessToken);
            const newExpiry = decodeToken(newAccessToken);
            setTokenExpiry(newExpiry);
            console.log('Token refreshed successfully.');
        } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout(); // Optionally logout user if refresh fails
        }
    };

    // Function to handle logout
    const handleLogout = () => {
        setUserRole(null);
        setJwtToken(null);
        setRefreshToken(null);
        setTokenExpiry(null);
        sessionStorage.clear();
        // Redirect to login page or show a message
    };

    // Effect to set token expiry and refresh timer
    useEffect(() => {
        if (jwtToken) {
            const expiry = decodeToken(jwtToken);
            setTokenExpiry(expiry);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeLeft = expiry - currentTime;

            // Refresh the token 5 minutes before it expires
            const refreshTime = (timeLeft - 300) * 1000;

            if (refreshTime > 0) {
                const timer = setTimeout(() => {
                    refreshAccessToken();
                }, refreshTime);

                return () => clearTimeout(timer);
            } else {
                // Token is about to expire or already expired
                refreshAccessToken();
            }
        }
    }, [jwtToken, refreshToken]);

    return (
        <UserContext.Provider value={{ userRole, setUserRole, jwtToken, setJwtToken, refreshToken, setRefreshToken, handleLogout }}>
            {children}
        </UserContext.Provider>
    );
};
