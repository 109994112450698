// src/components/AllocationModal/AllocationModal.jsx

import React, { useState, useEffect, useContext } from 'react';
import { 
  Modal, 
  Button, 
  Form, 
  Dropdown, 
  Loader, 
  Message, 
  Input, 
  Grid, 
  Segment, 
  Header 
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ProfileContext } from '../../context/ProfileContext';
import { UserContext } from '../../context/UserContext';
import useAxios from '../../axiosSetup';

const AllocationModal = ({
  open,
  onClose,
  onSave,
  employeeData,          
  clientProjectData,     
  allocationData,        
  userRole,
}) => {
  const { displayName } = useContext(ProfileContext);

  // Initialize state with either existing allocation data or default values
  const [formData, setFormData] = useState({
    employeeName: '',
    employeeId: '',
    clientId: '',
    projectId: '',
    status: '',
    allocationPercent: '',
    allocationPercentDropdown: '',
    billingType: '',
    billedCheck: '',
    timeSheetApprover: '',
    timeSheetApproverId: '',
    startDate: '',
    endDate: '',
  });
  const { jwtToken } = useContext(UserContext);
  const axiosInstance = useAxios();
  const [error, setError] = useState(null);
  const [allocationTotalWarning, setAllocationTotalWarning] = useState(false); // New state for Total allocation warning
  const [allocationWarning, setAllocationWarning] = useState(false); // New state for Allocation Warning
  const [allocationDropdownClicked, setAllocationDropdownClicked] = useState(false); // New Warning State to track dropdown click
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [timeSheetApprovers, setTimeSheetApprovers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [fetchedRemainingAllocation, setFetchedRemainingAllocation] = useState(100);
  const [originalAllocationPercent, setOriginalAllocationPercent] = useState(0);
  const [billingEnabled, setBillingEnabled] = useState('no');
  const [allocation, setAllocation] = useState(0); // For circular progress

  // New States for Date Constraints
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(true);
  const [minEndDate, setMinEndDate] = useState('');

  const [totalAllocation, setTotalAllocation] = useState(0);
  const [remainingAllocation, setRemainingAllocation] = useState(100);
  const [currentAllocation, setCurrentAllocation] = useState(0);

  useEffect(() => {
    if (allocationData) {
      // Do not override timeSheetApprover when editing
      return;
    }
    if (formData.clientId) {
      const associatedProjects = projects.filter(
        project => project.ClientID === formData.clientId
      );

      if (associatedProjects.length > 0) {
        const defaultApprover = associatedProjects[0].ProjectManager;
        setFormData(prev => ({
          ...prev,
          timeSheetApprover: defaultApprover || '', // Set to ProjectManager or empty string if undefined
        }));
      } else {
        // If no projects are associated, clear the Time Sheet Approver
        setFormData(prev => ({
          ...prev,
          timeSheetApprover: '',
        }));
      }
    } else {
      // If no client is selected, clear the Time Sheet Approver
      setFormData(prev => ({
        ...prev,
        timeSheetApprover: '',
      }));
    }
  }, [formData.clientId, projects, allocationData]);

  useEffect(() => {
    const fetchTotalAllocation = async () => {
      if (!formData.employeeId || !formData.startDate || !formData.endDate) return;

      try {
        const response = await axiosInstance.get(`api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        const newTotalAllocation = 100 - response.data.remainingAllocation;
        setTotalAllocation(newTotalAllocation);
        setRemainingAllocation(response.data.remainingAllocation);

        // If editing an existing allocation, set the current allocation
        if (allocationData) {
          const existingAllocation = allocationData.AllocationPercent || 0;
          setCurrentAllocation(existingAllocation);
          setFormData(prev => ({
            ...prev,
            allocationPercent: existingAllocation.toString(),
          }));
        }

        // Set warning if total allocation is 50% or below
        setAllocationTotalWarning(newTotalAllocation < 50 && newTotalAllocation > 0);
      } catch (err) {
        console.error('Error fetching total allocation:', err);
        setError('Failed to compute total allocation.');
      }
    };

    if (open) {
      fetchTotalAllocation();
    }
  }, [open, formData.employeeId, formData.startDate, formData.endDate, allocationData]);

  // Fetch clients, projects, and timeSheetApprovers when the modal opens
  useEffect(() => {
    const fetchModalData = async () => {
      setLoading(true);
      setFetchError(null);
      try {
        const response = await axiosInstance.get(`/api/modal/data`);
        const { clients, projects, timeSheetApprovers } = response.data;
        setClients(clients);
        setProjects(projects);
        setTimeSheetApprovers(timeSheetApprovers);
      } catch (err) {
        console.error('Error fetching modal data:', err);
        setFetchError('Failed to load form data.');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchModalData();
    }
  }, [open]);

  // Fetch remaining allocation when employeeData or allocationData changes
  useEffect(() => {
    const fetchRemainingAllocation = async () => {
      if (!formData.employeeId || !formData.startDate || !formData.endDate) return;

      try {
        const response = await axiosInstance.get(`/api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        setFetchedRemainingAllocation(response.data.remainingAllocation);
        setAllocation(100 - response.data.remainingAllocation); // Update allocation for the donut chart
      } catch (err) {
        console.error('Error fetching remaining allocation:', err);
        setError('Failed to compute remaining allocation.');
      }
    };

    if (open && formData.employeeId && formData.startDate && formData.endDate) {
      fetchRemainingAllocation();
    }
  }, [open, formData.employeeId, formData.startDate, formData.endDate]);

  // Update formData when allocationData, employeeData, or clientProjectData changes
  useEffect(() => {
    if (allocationData) {
      setFormData({
        // Use employeeData for Employee Name and ID
        employeeName: employeeData ? employeeData.EmployeeName : '',
        employeeId: employeeData ? employeeData.EmployeeId : '',
        clientId: allocationData.ClientID || '',
        projectId: allocationData.ProjectID || '',
        status: allocationData.AllocationStatus || '',
        allocationPercentDropdown: allocationData.allocationPercentDropdown ? allocationData.allocationPercentDropdown.toString() : '',
        allocationPercent: allocationData.AllocationPercent ? allocationData.AllocationPercent.toString() : '',
        billingType: allocationData.AllocationBillingType || '',
        billedCheck: allocationData.AllocationBilledCheck || '',
        timeSheetApprover: allocationData.AllocationTimeSheetApprover || '',
        timeSheetApproverId: allocationData.AllocationTimeSheetApproverID || '',
        startDate: allocationData.AllocationStartDate ? allocationData.AllocationStartDate.substring(0, 10) : '',
        endDate: allocationData.AllocationEndDate ? allocationData.AllocationEndDate.substring(0, 10) : '',
      });
      setOriginalAllocationPercent(allocationData.AllocationPercent || 0);
      setAllocation(allocationData.AllocationPercent || 0);

      // Enable End Date field if Start Date is present
      if (allocationData.AllocationStartDate) {
        setIsEndDateDisabled(false);
        setMinEndDate(allocationData.AllocationStartDate.substring(0, 10));
      }
    } else {
      // Reset form for adding new allocation
      setFormData({
        employeeName: employeeData ? employeeData.EmployeeName : '',
        employeeId: employeeData ? employeeData.EmployeeId : '',
        clientId: clientProjectData ? clientProjectData.clientId : '',
        projectId: clientProjectData ? clientProjectData.projectId : '',
        status: '',
        allocationPercentDropdown: '',
        allocationPercent: '',
        billingType: '',
        billedCheck: '',
        timeSheetApprover: '',
        timeSheetApproverId: '',
        startDate: '',
        endDate: '',
      });
      setOriginalAllocationPercent(0);
      setAllocation(0);
      setIsEndDateDisabled(true);
      setMinEndDate('');
    }

    // Reset error when allocationData changes
    setError(null);
  }, [allocationData, employeeData, clientProjectData]);

  // Reset form when modal is closed
  useEffect(() => {
    if (!open) {
      // Reset form when modal is closed
      setFormData({
        employeeName: employeeData ? employeeData.EmployeeName : '',
        employeeId: employeeData ? employeeData.EmployeeId : '',
        clientId: clientProjectData ? clientProjectData.clientId : '',
        projectId: clientProjectData ? clientProjectData.projectId : '',
        status: '',
        allocationPercent: '',
        billingType: '',
        billedCheck: '',
        timeSheetApprover: '',
        timeSheetApproverId: allocationData ? allocationData.AllocationTimeSheetApproverID || '' : '',
        startDate: '',
        endDate: '',
      });
      setError(null);
      setFetchError(null);
      setFetchedRemainingAllocation(100);
      setOriginalAllocationPercent(0);
      setRemainingAllocation(100);
      setBillingEnabled('no');
      setAllocation(0);
      setIsEndDateDisabled(true);
      setMinEndDate('');
      setAllocationTotalWarning(false); // Reset Total allocation warning when modal closes
      setAllocationWarning(false); // Reset Test
      setAllocationDropdownClicked(false); // Reset Test
    }
  }, [open, employeeData, clientProjectData, allocationData]);

  // Compute Remaining Allocation Dynamically
  useEffect(() => {
    let newRemaining = 0;
    const currentAllocationPercent = parseInt(formData.allocationPercent, 10) || 0;

    if (allocationData) {
      // If editing, subtract the current allocation percent from total allocation
      newRemaining = fetchedRemainingAllocation + (originalAllocationPercent || 0) - currentAllocationPercent;
    } else {
      // Adding a new allocation
      newRemaining = fetchedRemainingAllocation - currentAllocationPercent;
    }

    setRemainingAllocation(newRemaining >= 0 ? newRemaining : 0);
  }, [fetchedRemainingAllocation, originalAllocationPercent, formData.allocationPercent, allocationData]);

  // Helper function to check date overlap
  const isOverlapping = (newStart, newEnd, existingStart, existingEnd) => {
    const startA = new Date(newStart);
    const endA = newEnd ? new Date(newEnd) : new Date('9999-12-31');
    const startB = new Date(existingStart);
    const endB = existingEnd ? new Date(existingEnd) : new Date('9999-12-31');

    return startA <= endB && startB <= endA;
  };

  // Auto-populate Employee Name when Employee ID is entered and vice versa
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      if (formData.employeeId && !employeeData) {
        try {
          const response = await axiosInstance.get(`/api/employees/${formData.employeeId}`);
          if (response.data) {
            setFormData(prev => ({
              ...prev,
              employeeName: response.data.EmployeeName || '',
            }));
          }
        } catch (err) {
          console.error('Error fetching employee details:', err);
          setError('Invalid Employee ID.');
        }
      }
    };

    const fetchEmployeeId = async () => {
      if (formData.employeeName && !employeeData) {
        try {
          const response = await axiosInstance.get(`/api/employees?name=${formData.employeeName}`);
          if (response.data && response.data.EmployeeId) {
            setFormData(prev => ({
              ...prev,
              employeeId: response.data.EmployeeId || '',
            }));
          }
        } catch (err) {
          console.error('Error fetching employee ID:', err);
          setError('Invalid Employee Name.');
        }
      }
    };

    if (formData.employeeId && !formData.employeeName) {
      fetchEmployeeDetails();
    }

    if (formData.employeeName && !formData.employeeId) {
      fetchEmployeeId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.employeeId, formData.employeeName]);

  // Handle form field changes
  const handleChange = (e, { name, value }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'allocationPercent') {
      const newAllocationPercent = parseInt(value, 10) || 0;
      setCurrentAllocation(newAllocationPercent);

      // Set allocation warning if below 50%
      if (newAllocationPercent < 50 && totalAllocation < 50) {
        setAllocationWarning(true);
      } else {
        setAllocationWarning(false);
      }
    }

    // If allocationPercentDropdown changes, update allocationPercent as well
    if (name === 'allocationPercentDropdown') {
      setFormData((prev) => ({
        ...prev,
        allocationPercent: value,
      }));
      const dropdownValue = parseInt(value, 10) || 0;
      setAllocation(dropdownValue);

      // Set allocation warning if below 50%
      if (dropdownValue < 50 && totalAllocation < 50 && (dropdownValue + totalAllocation) < 50) {
        setAllocationWarning(true);
      } else {
        setAllocationWarning(false);
      }
    }

    if (name === 'billedCheck') {
      handleBillingChange(e, { value });

      if (value === 'No') {
        setFormData((prev) => ({
          ...prev,
          billingType: 'Internal', // Set your default value here
        }));
      }
    }

    if (name === 'timeSheetApprover') {
      // The value is the EmployeeID
      // Find the approver name based on EmployeeID
      const approver = getTimeSheetApproverOptions().find(option => option.value === value);
  
      if (approver) {
        // Extract the name from the 'text' property
        const nameWithRoles = approver.text;
        const name = nameWithRoles.split('(')[0].trim(); // Extract the name before any roles
        setFormData(prev => ({
          ...prev,
          timeSheetApproverId: value,
          timeSheetApprover: name || '',
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          timeSheetApproverId: '',
          timeSheetApprover: '',
        }));
      }
    } else if (name === 'projectId') {
      const selectedProject = projects.find(project => project.ProjectID === value);
      if (selectedProject) {
        const { ProjectManagerID, ProjectManager, StudioHeadID, StudioHeadName } = selectedProject;

        // Set Time Sheet Approver to Project Manager by default
        if (ProjectManagerID && ProjectManager) {
          setFormData((prev) => ({
            ...prev,
            timeSheetApproverId: ProjectManagerID,
            timeSheetApprover: ProjectManager,
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            timeSheetApproverId: '',
            timeSheetApprover: '',
          }));
        }

        // Set Client ID automatically based on selected Project
        setFormData((prev) => ({
          ...prev,
          projectId: value,
          clientId: selectedProject.ClientID,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          timeSheetApproverId: '',
          timeSheetApprover: '',
        }));
      }
    }

    // Dynamically update status based on allocation percentage and selected fields
    if (name === 'allocationPercent') {
      const allocationValue = parseInt(value, 10);
      if (
        formData.clientId &&
        formData.projectId &&
        (allocationValue === 0)
      ) {
        setFormData((prev) => ({ ...prev, status: 'Project Unallocated' }));
      } else if (
        formData.clientId &&
        formData.projectId &&
        allocationValue > 0
      ) {
        setFormData((prev) => ({ ...prev, status: 'Allocated' }));
      } else if (formData.clientId && !formData.projectId) {
        setFormData((prev) => ({ ...prev, status: 'Client Unallocated' }));
      } else {
        setFormData((prev) => ({ ...prev, status: '' }));
      }
    }

    // Reset error when user modifies any field
    setError(null);
  };

  // Validate form fields (pure function without side effects)
  const isFormValid = () => {
    const {
      clientId,
      projectId,
      status,
      allocationPercent,
      billingType,
      billedCheck,
      timeSheetApprover,
      timeSheetApproverId,
      startDate,
      endDate,
    } = formData;

    // Basic required fields validation
    if (
      (!clientProjectData && (!clientId || !projectId)) || // If not prefilled, client and project are required
      !status ||
      allocationPercent === '' ||
      !billingType ||
      !billedCheck ||
      !timeSheetApprover ||
      !timeSheetApproverId ||
      !startDate ||
      !endDate // Ensure endDate is always required
    ) {
      return false;
    }

    // Check if start date is before 2020
    const start = new Date(startDate);
    const minStartDate = new Date('2020-01-01');
    if (start < minStartDate) {
      return false;
    }

    // Additional validation for AllocationEndDate
    if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Basic validation
    if (!isFormValid()) {
      // Determine the specific error message
      const {
        clientId,
        projectId,
        status,
        allocationPercent,
        billingType,
        billedCheck,
        timeSheetApprover,
        timeSheetApproverId,
        startDate,
        endDate,
      } = formData;

      if (
        (!clientProjectData && (!clientId || !projectId)) ||
        !status ||
        allocationPercent === '' ||
        !billingType ||
        !billedCheck ||
        !timeSheetApprover ||
        !timeSheetApproverId ||
        !startDate ||
        !endDate
      ) {
        setError('Please fill in all required fields correctly.');
        return;
      }

      if (billedCheck === 'Yes') {
        // Removed Billing Rate validation
      }

      const start = new Date(startDate);
      const minStartDate = new Date('2020-01-01');
      if (start < minStartDate) {
        setError('Start Date cannot be before January 1, 2020.');
        return;
      }

      if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
        setError('End Date cannot be before Start Date.');
        return;
      }

      // If none of the above, set a generic error
      setError('Please fill in all required fields correctly.');
      return;
    }

    // Validate Billing Type only if Billed? is Yes
    if (formData.billedCheck === 'Yes') {
      // No Billing Rate validation needed
    }

    try {
      // Check for overlapping allocations
      const overlapResponse = await axiosInstance.get(`/api/employeeAllocationDetails/${formData.employeeId}/allocations`);
      const allocationsList = overlapResponse.data.allocations;

      const hasOverlap = allocationsList.some(alloc => {
        // If editing, exclude the current allocation
        if (allocationData && alloc.AllocationID === allocationData.AllocationID) {
          return false;
        }

        // Check if the project is the same
        if (alloc.ProjectID !== formData.projectId) {
          return false;
        }

        // Check for date overlap
        return isOverlapping(
          formData.startDate,
          formData.endDate,
          alloc.AllocationStartDate,
          alloc.AllocationEndDate
        );
      });

      if (hasOverlap) {
        setError('Allocation overlaps with an existing allocation for the same project.');
        return;
      }

      // Check total allocation does not exceed 100%
      if (allocationData) {
        // If editing, subtract the current allocation percent from total allocation
        const totalAllocationResponse = await axiosInstance.get(`/api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        const totalAllocation = 100 - totalAllocationResponse.data.remainingAllocation;
        const adjustedTotal = totalAllocation - originalAllocationPercent + parseInt(formData.allocationPercent, 10);

        if (adjustedTotal > 100) {
          setError('Total allocation percentage cannot exceed 100%.');
          return;
        }
      } else {
        // If adding new allocation
        const totalAllocationResponse = await axiosInstance.get(`/api/employeeAllocations/${formData.employeeId}`, {
          params: {
            startDate: formData.startDate,
            endDate: formData.endDate
          }
        });
        const totalAllocation = 100 - totalAllocationResponse.data.remainingAllocation;

        if (totalAllocation + parseInt(formData.allocationPercent, 10) > 100) {
          setError('Total allocation percentage cannot exceed 100%.');
          return;
        }
      }

      // Prepare the payload
      const payload = {
        EmployeeID: formData.employeeId,
        ClientID: formData.clientId,
        ProjectID: formData.projectId,
        AllocationStatus: formData.status,
        AllocationPercentDropdown: parseInt(formData.allocationPercentDropdown, 10),
        AllocationPercent: parseInt(formData.allocationPercent, 10),
        AllocationStartDate: formData.startDate,
        AllocationEndDate: formData.endDate || null,
        AllocationTimeSheetApproverID: formData.timeSheetApproverId,
        AllocationTimeSheetApprover: formData.timeSheetApprover,
        AllocationBillingType: formData.billingType,
        AllocationBilledCheck: formData.billedCheck,
        // Removed AllocationBillingRate from payload
        ModifiedBy: displayName,
      };
      
      if (allocationData && allocationData.AllocationID) {
        // Editing existing allocation
        await axiosInstance.put(`/api/allocations/${allocationData.AllocationID}`, payload);
        toast.success('Allocation updated successfully!');
      } else {
        // Adding new allocation
        await axiosInstance.post(`api/api/allocate`, payload); // Corrected API endpoint
        toast.success('Allocation added successfully!');
      }

      onSave(); // Refresh allocations in parent component
      onClose(); // Close the modal
    } catch (err) {
      console.error('Error adding/updating allocation:', err);
      setError(err.response?.data?.message || 'Failed to add/update allocation.');
      setFormData(prev => ({
        ...prev,
        allocationPercentDropdown: '', // Reset allocationPercentDropdown to empty
      }));
    }
  };

  // Helper function to generate project options based on selected client
  const getFilteredProjectOptions = () => {
    if (formData.clientId === '') {
      return [];
    }

    return projects
      .filter(project => project.ClientID === formData.clientId)
      .map(project => ({
        key: project.ProjectID,
        text: project.ProjectName,
        value: project.ProjectID,
      }));
  };

  const AllocationPercentOptions = [
    { key: 0, text: '0%', value: '0' },
    { key: 25, text: '25%', value: '25' },
    { key: 50, text: '50%', value: '50' },
    { key: 75, text: '75%', value: '75' },
    { key: 100, text: '100%', value: '100' },
  ];
  
  // Updated Helper function to get Time Sheet Approver options with multiple role tags
  const getTimeSheetApproverOptions = () => {
    const selectedProject = projects.find(project => project.ProjectID === formData.projectId);
    
    // Create a Map to aggregate roles per approver
    const approverMap = new Map();

    // Add Project Manager
    if (selectedProject && selectedProject.ProjectManagerID && selectedProject.ProjectManager) {
      const pmId = selectedProject.ProjectManagerID;
      const pmName = selectedProject.ProjectManager;
      if (!approverMap.has(pmId)) {
        approverMap.set(pmId, { name: pmName, roles: ['Project Manager'] });
      } else {
        approverMap.get(pmId).roles.push('Project Manager');
      }
    }

    // Add Studio Head
    if (selectedProject && selectedProject.StudioHeadID && selectedProject.StudioHeadName) {
      const shId = selectedProject.StudioHeadID;
      const shName = selectedProject.StudioHeadName;
      if (!approverMap.has(shId)) {
        approverMap.set(shId, { name: shName, roles: ['Studio Head'] });
      } else {
        approverMap.get(shId).roles.push('Studio Head');
      }
    }

    // Add Bizops Employees
    timeSheetApprovers.forEach(approver => {
      const { EmployeeID, Name } = approver;
      if (!approverMap.has(EmployeeID)) {
        approverMap.set(EmployeeID, { name: Name, roles: ['Bizops'] });
      } else {
        approverMap.get(EmployeeID).roles.push('Bizops');
      }
    });

    // Add Allocated Employees from the selected project
    if (selectedProject && selectedProject.allocatedEmployees) {
      selectedProject.allocatedEmployees.forEach(emp => {
        if (emp.employeeId && emp.employeeName) {
          const empId = emp.employeeId;
          const empName = emp.employeeName;
          if (!approverMap.has(empId)) {
            approverMap.set(empId, { name: empName, roles: ['Allocated Employee'] });
          } else {
            approverMap.get(empId).roles.push('Allocated Employee');
          }
        }
      });
    }

    // Convert the Map to an array of options
    let approverOptions = Array.from(approverMap.entries()).map(([id, { name, roles }]) => ({
      key: id,
      text: `${name} ${roles.map(role => `(${role})`).join(' ')}`, // Text for search
      value: id,
      content: (
        <div>
          {name}
          {roles.map((role, index) => (
            <span 
              key={index} 
              style={{ 
                fontStyle: 'italic', 
                fontWeight: 'bold', 
                color: 'black', 
                marginLeft: '5px' 
              }}
            >
              ({role})
            </span>
          ))}
        </div>
      ),
      roles: roles, // Keeping the roles array for potential future use
    }));

    // If editing an existing allocation, ensure the current approver is included
    if (
      allocationData && 
      allocationData.AllocationTimeSheetApproverID &&
      !approverMap.has(allocationData.AllocationTimeSheetApproverID)
    ) {
      approverOptions.push({
        key: allocationData.AllocationTimeSheetApproverID,
        text: `${allocationData.AllocationTimeSheetApprover} ${allocationData.AllocationTimeSheetApproverRole ? allocationData.AllocationTimeSheetApproverRole.map(role => `(${role})`).join(' ') : ''}`,
        value: allocationData.AllocationTimeSheetApproverID,
        content: (
          <div>
            {allocationData.AllocationTimeSheetApprover}
            {allocationData.AllocationTimeSheetApproverRole && allocationData.AllocationTimeSheetApproverRole.map((role, index) => (
              <span 
                key={index} 
                style={{ 
                  fontStyle: 'italic', 
                  fontWeight: 'bold', 
                  color: 'black', 
                  marginLeft: '5px' 
                }}
              >
                ({role})
              </span>
            ))}
          </div>
        ),
        roles: allocationData.AllocationTimeSheetApproverRole || [],
      });
    }

    return approverOptions;
  };

  // Handle Billing Radio Change
  const handleBillingChange = (e, { value }) => {
    setFormData((prev) => ({
      ...prev,
      billedCheck: value,
      // Removed billingRate logic
    }));
  };

  // Custom styles for Allocation Percent Dropdown when warning is active
  const allocationDropdownStyle = allocationTotalWarning ? { borderColor: '#fbbd08 !important', backgroundColor: '#fff8e1' } : {};

  const handleDropdownClick = () => {
    setAllocationDropdownClicked(true);
  };

  return (
    <Modal open={open} onClose={onClose} size="large" closeIcon>
      <Modal.Header>
        {allocationData ? 'Edit Allocation' : 'Add New Allocation'}
      </Modal.Header>
      <Modal.Content scrolling>
        {loading ? (
          <Loader active inline="centered" />
        ) : fetchError ? (
          <Message negative>
            <Message.Header>Error</Message.Header>
            <p>{fetchError}</p>
          </Message>
        ) : (
          <>
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error}</p>
              </Message>
            )}
            {/* Display warning message if Total allocation is below 50% */}
            { (allocationTotalWarning || (allocationWarning && allocationDropdownClicked)) && (
              <Message warning>
                <Message.Header>Warning</Message.Header>
                <p>Resource allocation is below 50%.</p>
              </Message>
            )}
            <Grid stackable divided>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>Start Date</label>
                        <Input
                          type="date"
                          name="startDate"
                          value={formData.startDate}
                          onChange={(e, { name, value }) => {
                            handleChange(e, { name, value });
                            if (value) {
                              setIsEndDateDisabled(false);
                              setMinEndDate(value);
                              setFormData(prev => ({
                                ...prev,
                                endDate: '', // Reset End Date when Start Date changes
                              }));
                            } else {
                              setIsEndDateDisabled(true);
                              setMinEndDate('');
                            }
                          }}
                          min="2020-01-01"
                          max="2030-12-31"
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>End Date</label>
                        <Input
                          type="date"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                          min={minEndDate}
                          disabled={isEndDateDisabled}
                          readOnly={false} // Users can select from calendar
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                      {/* Always display Employee Name and ID as read-only */}
                      <Form.Input
                        label="Employee Name"
                        placeholder="Employee Name"
                        name="employeeName"
                        value={formData.employeeName}
                        readOnly
                        required
                      />
                      <Form.Input
                        label="Employee ID"
                        placeholder="Employee ID"
                        name="employeeId"
                        value={formData.employeeId}
                        readOnly
                        required
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>Client Name</label>
                        <Dropdown
                          placeholder="Select Client"
                          fluid
                          search
                          selection
                          options={clients.map(client => ({
                            key: client.ClientID,
                            text: client.ClientName,
                            value: client.ClientID,
                          }))}
                          name="clientId"
                          value={formData.clientId}
                          onChange={handleChange}
                          clearable={!clientProjectData && !formData.projectId} // Allow clearing only if projectId is not set
                          disabled={!!formData.projectId || !!clientProjectData} // Disable if projectId is set or clientProjectData is provided
                          upward={true}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Project Name</label>
                        <Dropdown
                          placeholder="Select Project"
                          fluid
                          search
                          selection
                          options={getFilteredProjectOptions()}
                          name="projectId"
                          value={formData.projectId}
                          onChange={handleChange}
                          clearable={!clientProjectData}
                          upward={true}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Segment>
                    <Header as='h4' dividing>
                      Allocation %
                    </Header>
                    <div style={{ width: 150, height: 150, margin: '0 auto' }}>
                      <CircularProgressbar 
                        value={totalAllocation} 
                        text={`${totalAllocation}%`} 
                        styles={buildStyles({
                          textSize: '16px',
                          pathColor: '#3b82f6',
                          textColor: '#333',
                          trailColor: '#e2e8f0',
                        })}
                      />
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>Time Sheet Approver</label>
                        <Dropdown
                          placeholder="Select Approver"
                          fluid
                          search // Enable search
                          selection
                          options={getTimeSheetApproverOptions()}
                          name="timeSheetApprover"
                          value={formData.timeSheetApproverId}
                          onChange={handleChange}
                          clearable
                          upward={true}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Allocation %</label>
                        <Dropdown
                          placeholder="Select Allocation %"
                          fluid
                          search // Enable search for consistency
                          selection
                          options={AllocationPercentOptions.filter(option => 
                            parseInt(option.value, 10) <= (remainingAllocation + currentAllocation)
                          )}
                          name="allocationPercent"
                          value={formData.allocationPercent}
                          onChange={handleChange}
                          onClick={handleDropdownClick} // Track dropdown click
                          upward={true}
                          clearable
                          style={allocationDropdownStyle} // Apply conditional styling
                        />
                        <div style={{ marginTop: '5px', color: 'gray', fontSize: '12px' }}>
                          Remaining Allocation: {remainingAllocation}%
                        </div>
                      </Form.Field>
                      <Form.Field required>
                        <label>Status</label>
                        <Dropdown
                          placeholder="Set Status"
                          fluid
                          search // Enable search for consistency
                          selection
                          options={[
                            { key: 'client-unallocated', text: 'Client Unallocated', value: 'Client Unallocated' },
                            { key: 'project-unallocated', text: 'Project Unallocated', value: 'Project Unallocated' },
                            { key: 'allocated', text: 'Allocated', value: 'Allocated' },
                            { key: 'closed', text: 'Closed', value: 'Closed' },
                          ]}
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          clearable
                          upward={true}
                        />
                      </Form.Field>
                      
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>Billed?</label>
                        <Dropdown
                          placeholder="Select Billed Check"
                          fluid
                          search // Enable search for consistency
                          selection
                          options={[
                            { key: 'yes', text: 'Yes', value: 'Yes' },
                            { key: 'no', text: 'No', value: 'No' },
                          ]}
                          name="billedCheck"
                          value={formData.billedCheck}
                          onChange={handleChange}
                          clearable
                          upward={true}
                        />
                      </Form.Field>

                      <Form.Field required>
                        <label>Billing Type</label>
                        <Dropdown
                          placeholder="Select Billing Type"
                          fluid
                          search // Enable search for consistency
                          selection
                          options={[
                            { key: 'tm', text: 'T&M', value: 'T&M' },
                            { key: 'fix', text: 'Fix Price', value: 'Fix Price' },
                            { key: 'internal', text: 'Internal', value: 'Internal' },
                          ]}
                          name="billingType"
                          value={formData.billingType}
                          onChange={handleChange}
                          disabled={formData.billedCheck === 'No'} // Disable if Billed? is No
                          upward={true}
                          clearable
                        />
                      </Form.Field>

                      {/* Removed Billing Rate field */}
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          color="blue" 
          onClick={handleSubmit} 
          disabled={!isFormValid() || loading} // No state updates within isFormValid
        >
          {allocationData ? 'Update' : 'Save'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

// Define PropTypes for better type checking
AllocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  employeeData: PropTypes.shape({
    EmployeeName: PropTypes.string,
    EmployeeId: PropTypes.string,
  }), // Optional: Object containing EmployeeName and EmployeeID
  clientProjectData: PropTypes.shape({
    clientId: PropTypes.number,
    projectId: PropTypes.number,
  }), // Optional: Object containing ClientID and ProjectID
  allocationData: PropTypes.shape({
    AllocationID: PropTypes.number,
    ClientID: PropTypes.number,
    ProjectID: PropTypes.number,
    AllocationStatus: PropTypes.string,
    allocationPercentDropdown: PropTypes.number,
    AllocationPercent: PropTypes.number,
    AllocationStartDate: PropTypes.string,
    AllocationEndDate: PropTypes.string,
    AllocationTimeSheetApprover: PropTypes.string,
    AllocationBillingType: PropTypes.string,
    AllocationBilledCheck: PropTypes.string,
    ModifiedBy: PropTypes.string,
    ModifiedAt: PropTypes.string,
    AllocationTimeSheetApproverRole: PropTypes.arrayOf(PropTypes.string), // Added for roles
  }), // Allocation details or null
  userRole: PropTypes.string.isRequired,
};

export default AllocationModal;
