// src/context/ProfileContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "./UserContext";
import { ROLES } from "../constants/roles";
import config from "../Config.json";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState({ displayName: "", userPrincipalName: "" });
    const { setUserRole, setJwtToken, setRefreshToken } = useContext(UserContext);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                let profileData = sessionStorage.getItem("profileData");
                if (!profileData) {
                    const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`,
                        },
                    });
                    profileData = response.data;
                    sessionStorage.setItem("profileData", JSON.stringify(profileData));
                } else {
                    profileData = JSON.parse(profileData);
                }

                setProfile(profileData);

                // Ensure userPrincipalName exists
                if (!profileData.userPrincipalName) {
                    throw new Error("UserPrincipalName is missing from profile data.");
                }

                // Fetch user role and tokens from the API
                const encodedEmail = encodeURIComponent(profileData.userPrincipalName);
                const roleResponse = await axios.get(`${config.azureApiUrl}/api/getUserRole/${encodedEmail}`);

                // Extract data
                const tempRole = roleResponse.data.userRole;
                const accessToken = roleResponse.data.accessToken; // Access token
                const refreshToken = roleResponse.data.refreshToken; // Refresh token

                // Determine user role based on API response
                let userRole;
                switch (tempRole.toUpperCase()) {
                    case "LEADER":
                        userRole = ROLES.LEADER;
                        break;
                    case "BIZOPS":
                        userRole = ROLES.BIZOPS;
                        break;
                    case "MANAGER":
                        userRole = ROLES.MANAGER;
                        break;
                    case "EMPLOYEE":
                        userRole = ROLES.EMPLOYEE;
                        break;
                    case "ADMIN":
                        userRole = ROLES.ADMIN;
                        break;
                    default:
                        userRole = ROLES.EMPLOYEE;
                }

                // Update context and sessionStorage
                setUserRole(userRole);
                setJwtToken(accessToken);
                setRefreshToken(refreshToken);
                sessionStorage.setItem("jwtToken", accessToken);
                sessionStorage.setItem("refreshToken", refreshToken);
            } catch (error) {
                console.error("Error fetching profile or user role:", error);
            }
        };
        fetchProfile();
    }, [setUserRole, setJwtToken, setRefreshToken]);

    return (
        <ProfileContext.Provider value={profile}>
            {children}
        </ProfileContext.Provider>
    );
};
