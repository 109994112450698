import React, { useState, useEffect } from 'react';
import { Container, Header, Image } from 'semantic-ui-react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../assets/animation-loading.json'; // Local path to your JSON file
import lockIcon from '../assets/images/locked.png'; // Path to the locked image

const NotAuthorized = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set a timeout to stop loading after 10 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000); // 10000 ms = 10 seconds

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center'
  };

  const errorHeaderStyle = {
    color: '#db2828', // Semantic UI's red color
    fontWeight: 'bold',
    marginBottom: '1rem'
  };

  const messageStyle = {
    fontSize: '1.5em',
    lineHeight: '1.6',
    marginTop: '1rem'
  };

  return (
    <Container style={containerStyle}>
      {isLoading ? (
        // Loading animation
        <>
          <Player
            autoplay
            loop
            src={animationData}
            style={{ width: '300px', height: '300px' }}
          />
          <Header as='h3' color='grey' style={{ marginTop: '1rem' }}>
            Loading...
          </Header>
        </>
      ) : (
        // User-friendly error message with locked image
        <>
          <Image
            src={lockIcon}
            size='small'
            style={{
              width: '120px',
              height: '120px',
              marginBottom: '20px'
            }}
            alt="Locked"
          />
          <Header as='h1' style={errorHeaderStyle}>
            Unable to Authenticate
          </Header>
          <div style={messageStyle}>
            <p>We're sorry, but we couldn't verify your access at this moment.</p>
            Please try again later or contact
            <strong> timesheets@innoverdigital.com </strong> or your project manager for further assistance.
          </div>
        </>
      )}
    </Container>
  );
};

export default NotAuthorized;