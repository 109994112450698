// src/axiosSetup.js
import axios from 'axios';
import { useContext, useMemo } from 'react';
import { UserContext } from './context/UserContext';
import config from "./Config.json";

const useAxios = () => {
    const { jwtToken, refreshToken, setJwtToken, setRefreshToken, handleLogout } = useContext(UserContext);

    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: config.azureApiUrl, // Your API base URL
        });

        // Request interceptor to add the JWT access token to headers
        instance.interceptors.request.use(
            config => {
                if (jwtToken) {
                    config.headers.Authorization = `Bearer ${jwtToken}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        // Flag to avoid multiple refresh attempts
        let isRefreshing = false;
        let failedQueue = [];

        const processQueue = (error, token = null) => {
            failedQueue.forEach(prom => {
                if (error) {
                    prom.reject(error);
                } else {
                    prom.resolve(token);
                }
            });

            failedQueue = [];
        };

        // Response interceptor to handle 401 errors
        instance.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    if (isRefreshing) {
                        return new Promise(function(resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        }).then(token => {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            return axios(originalRequest);
                        }).catch(err => {
                            return Promise.reject(err);
                        });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise(async (resolve, reject) => {
                        try {
                            const response = await axios.post(`${config.azureApiUrl}/api/refreshToken`, {}, {
                                headers: {
                                    Authorization: `Bearer ${refreshToken}`, // Use refresh token
                                },
                            });

                            const newAccessToken = response.data.accessToken;
                            setJwtToken(newAccessToken);
                            sessionStorage.setItem('jwtToken', newAccessToken);
                            instance.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
                            originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
                            processQueue(null, newAccessToken);
                            resolve(instance(originalRequest));
                        } catch (err) {
                            processQueue(err, null);
                            handleLogout();
                            reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    });
                }

                return Promise.reject(error);
            }
        );

        return instance;
    }, [jwtToken, refreshToken, setJwtToken, setRefreshToken, handleLogout]);

    return axiosInstance;
};

export default useAxios;
